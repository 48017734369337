import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {MenuItemCategoriaComponent} from "./menu-item-categoria.component";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild([{ path: '', component: MenuItemCategoriaComponent }])
  ],
  exports: [MenuItemCategoriaComponent],
  declarations: [MenuItemCategoriaComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MenuItemCategoriaComponentModule {}
