import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-menu-item-categoria',
  templateUrl: './menu-item-categoria.component.html',
  styleUrls: ['./menu-item-categoria.component.scss'],
})
export class MenuItemCategoriaComponent implements OnInit {

  @Input() disabilitato: boolean;

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

}
