import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'modifica-intro',
    loadChildren: () => import('./modifica-intro/modifica-intro.module').then( m => m.ModificaIntroPageModule)
  },
  {
    path: 'modifica-ristorante',
    loadChildren: () => import('./modifica-ristorante/modifica-ristorante.module').then( m => m.ModificaRistorantePageModule)
  },
  {
    path: 'modifica-categoria',
    loadChildren: () => import('./modifica-categoria/modifica-categoria.module').then( m => m.ModificaCategoriaPageModule)
  },
  {
    path: 'piatti',
    loadChildren: () => import('./piatti/piatti.module').then( m => m.PiattiPageModule)
  },
  {
    path: 'modifica-piatto',
    loadChildren: () => import('./modifica-piatto/modifica-piatto.module').then( m => m.ModificaPiattoPageModule)
  },
  {
    path: 'riordina-piatti',
    loadChildren: () => import('./riordina-piatti/riordina-piatti.module').then( m => m.RiordinaPiattiPageModule)
  },
  {
    path: 'riordina-categorie',
    loadChildren: () => import('./riordina-categorie/riordina-categorie.module').then( m => m.RiordinaCategoriePageModule)
  },
  {
    path: 'riordina-menu',
    loadChildren: () => import('./riordina-menu/riordina-menu.module').then( m => m.RiordinaMenuPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
