export class Ristorante {

  id: number;
  nome: string;
  descrizione: string;
  indirizzo: string;
  colore: string;
  telefono: string;
  sitoInternet: string;
  logo: string;
  logoMimeType: string;
  disabilitato: boolean;
  menuUrl: string;

  listaMenu: Menu[] = [];


  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this.id = data.Id;
    this.nome = data.Nome;
    this.logo = data.Logo;
    this.colore = data.Colore;
    this.indirizzo = data.Indirizzo;
    this.telefono = data.Telefono;
    this.sitoInternet = data.SitoInternet;
    this.disabilitato = data.Disabilitato;
    this.menuUrl = data.MenuUrl;
    if(!this.listaMenu || this.listaMenu.length <= 0){
      this.listaMenu = [];
      if(data.traduzioni){
        for(let rawTraduzione of data.traduzioni){
          this.listaMenu.push(new Menu(rawTraduzione));
        }
      }
    }else{
      if(data.traduzioni){
        let hash: any = {};
        for(let rawTraduzione of data.traduzioni){
          hash[rawTraduzione.Id] = rawTraduzione;
          let trovata: boolean = false;
          for(const menu of this.listaMenu){
            if(menu.id === rawTraduzione.Id){
              menu.setData(rawTraduzione);
              trovata = true;
              break;
            }
          }
          if(!trovata){
            this.listaMenu.push(new Menu(rawTraduzione));
          }
        }
        for(let i=0; i<this.listaMenu.length; i++){
          if(!hash.hasOwnProperty(this.listaMenu[i].id)){
            this.listaMenu.splice(i, 1);
            i--;
          }
        }
        this.listaMenu.sort((a, b) => {
          return (a.ordine || 0) - (b.ordine || 0);
        });
      }
    }
  }

}

export class Menu {

  id: number;
  nome: string;
  descrizione: string;
  descrizionePulita: string;
  codice: string;
  categorie: Categoria[] = [];
  ordine: number;
  disabilitato: number;

  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this.id = data.Id;
    this.codice = data.Codice;
    this.nome = data.Nome;
    this.descrizione = data.Descrizione;
    this.ordine = data.Ordine;
    this.disabilitato = data.Disabilitato;
    if(this.descrizione){
      this.descrizionePulita = this.descrizione.replace(/<[^>]*>/g, '');
    }
    if(data.categorie){
      if(!this.categorie || this.categorie.length <= 0){
        this.categorie = [];
        for(let rawCategoria of data.categorie){
          this.categorie.push(new Categoria(rawCategoria));
        }
      }else{
        let hash: any = {};
        for(let rawCategoria of data.categorie){
          hash[rawCategoria.Id] = rawCategoria;
          let trovata: boolean = false;
          for(const categoria of this.categorie){
            if(categoria.id === rawCategoria.Id){
              categoria.setData(rawCategoria);
              trovata = true;
              break;
            }
          }
          if(!trovata){
            this.categorie.push(new Categoria(rawCategoria));
          }
        }
        for(let i=0; i<this.categorie.length; i++){
          if(!hash.hasOwnProperty(this.categorie[i].id)){
            this.categorie.splice(i, 1);
            i--;
          }
        }
        this.categorie.sort((a, b) => {
          return (a.ordine || 0) - (b.ordine || 0);
        });
      }
    }
  }

}

export class Categoria {

  id: number;
  titolo: string;
  descrizione: string;
  descrizionePulita: string;
  ordine: number;
  disabilitato: boolean;

  piatti: Piatto[] = [];

  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this.id = data.Id;
    this.titolo = data.Titolo;
    this.descrizione = data.Descrizione;
    if(this.descrizione){
      this.descrizionePulita = this.descrizione.replace(/<[^>]*>/g, '');
    }
    this.ordine = data.Ordine;
    this.disabilitato = data.Disabilitato;
    if(data.piatti){
      if(!this.piatti || this.piatti.length <= 0){
        this.piatti = [];
        for(let rawPiatto of data.piatti){
          this.piatti.push(new Piatto(rawPiatto));
        }
      }else{
        let hash: any = {};
        for(let rawPiatto of data.piatti){
          hash[rawPiatto.Id] = rawPiatto;
          let trovata: boolean = false;
          for(const piatto of this.piatti){
            if(piatto.id === rawPiatto.Id){
              piatto.setData(rawPiatto);
              trovata = true;
              break;
            }
          }
          if(!trovata){
            this.piatti.push(new Piatto(rawPiatto));
          }
        }
        for(let i=0; i<this.piatti.length; i++){
          if(!hash.hasOwnProperty(this.piatti[i].id)){
            this.piatti.splice(i, 1);
            i--;
          }
        }
        this.piatti.sort((a, b) => {
          return (a.ordine || 0) - (b.ordine || 0);
        });
      }
    }
  }

}

export class Piatto {

  id: number;
  idCategoria: number;
  titolo: string;
  descrizione: string;
  descrizionePulita: string;
  prezzo: number; // centesimi di euro
  consigliato: boolean;
  glutenFree: boolean;
  vegano: boolean;
  vegetariano: boolean;
  piccante: boolean;
  ordine: number;
  disabilitato: boolean;


  constructor(data?: any) {
    if(data){
      this.setData(data);
    }
  }

  setData(data: any){
    this.id = data.Id;
    this.idCategoria = data.Id_categoria;
    this.titolo = data.Titolo;
    this.descrizione = data.Descrizione;
    if(this.descrizione){
      this.descrizionePulita = this.descrizione.replace(/<[^>]*>/g, '');
    }
    this.prezzo = data.Prezzo;
    this.consigliato = data.Consigliato;
    this.glutenFree = data.Gluten_free;
    this.vegano = data.Vegano;
    this.vegetariano = data.Vegetariano;
    this.piccante = data.Piccante;
    this.ordine = data.Ordine;
    this.disabilitato = data.Disabilitato;
  }

}

