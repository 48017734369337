import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {QuillModule} from "ngx-quill";
import {MenuItemPiattoComponent} from "../components/menu-item-piatto/menu-item-piatto.component";
import {MenuItemPiattoComponentModule} from "../components/menu-item-piatto/menu-item-piatto.mudule";
import {MenuItemCategoriaComponentModule} from "../components/menu-item-categoria/menu-item-categoria.module";
import {MenuItemCategoriaComponent} from "../components/menu-item-categoria/menu-item-categoria.component";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [MenuItemPiattoComponent, MenuItemCategoriaComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode: 'md'}),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    QuillModule.forRoot(),
    MenuItemPiattoComponentModule,
    MenuItemCategoriaComponentModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
