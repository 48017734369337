import {Component, Input, OnInit} from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {Piatto} from "../../models/ristorante";

@Component({
  selector: 'app-menu-item-piatto',
  templateUrl: './menu-item-piatto.component.html',
  styleUrls: ['./menu-item-piatto.component.scss'],
})
export class MenuItemPiattoComponent implements OnInit {

  @Input() disabilitato: boolean;

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
    console.log(this.disabilitato)
  }

}
