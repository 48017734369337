import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import * as firebase from 'firebase';
import {environment} from "../environments/environment";
import {Router} from "@angular/router";
import {DataService} from "../services/data/data.service";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private data: DataService
  ) {

    firebase.initializeApp(environment.firebase);

    firebase.auth().onAuthStateChanged(async (user) => {
      if(!user){
        console.log("not login");
        this.router.navigate(['login']);
        this.data.setUserStatus(1);

      }else{
        console.log("login");
        this.router.navigate(['tabs']);
        this.data.setUserStatus(1);
      }
    });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      if(this.platform.is('android')){
        this.statusBar.styleBlackOpaque();
        this.statusBar.backgroundColorByHexString('#000000');
      }
      this.splashScreen.hide();
    });
  }
}
