import { Injectable } from '@angular/core';
import {
  Categoria, Menu, Piatto,
  Ristorante,
} from "../../models/ristorante";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  userStatus: number = 0; // 0 = non ancora controllato; 1 = loggato; 2 = non loggato;
  email: string;
  dataFinePro: number;
  isPro: boolean;
  // ristoranti: Ristorante[] = [];
  ristorante: Ristorante = new Ristorante();
  ristorantePrelevato: boolean;
  menuCorrente: Menu;
  categoriaCorrente: Categoria;
  piattoCorrente: Piatto;

  updateLogged = new Subject<number>();
  updateLogged$ = this.updateLogged.asObservable();

  constructor() {
    console.log(1, this.ristorante)
  }

  setData(data: any, isUpdate?: boolean){
    console.log(data);
    this.ristorantePrelevato = true;
    this.email = data.email;
    if(data.data_fine_pro){
      this.dataFinePro = (new Date(data.data_fine_pro)).getTime();
      let now: any = new Date();
      if(this.dataFinePro >= now.getTime()){
        this.isPro = true;
      }
    }
    console.log(this.dataFinePro)
    if(data.ristoranti && data.ristoranti.length > 0){
      if(isUpdate){
        this.ristorante.setData(data.ristoranti[0]);
      }else{
        this.ristorante = new Ristorante(data.ristoranti[0]);
      }
    }
  }

  setUserStatus(status: number){
    this.userStatus = status;
    this.updateLogged.next(this.userStatus);
  }

}
